import React, { useState } from 'react'
import { graphql, PageRendererProps } from 'gatsby'
import useMedia from '@baevra/media/useMedia'
import Layout from 'src/layout'
import Header from 'src/layout/Header'
import SEO from 'src/components/SEO'
import HeroSection from 'src/sections/Hero'
import DiscoverSection from 'src/sections/Places'
import LearnHistory from 'src/sections/History'
import AwardsSection from 'src/sections/Awards'
import Navigation from 'src/components/Navigation'
import PageScroll from 'src/components/PageScroll'
import debounce from 'lodash/debounce'

export interface IndexPageProps extends PageRendererProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
  pageContext: any
}

const IndexPage:React.FC<IndexPageProps> = props => {
  const { pageContext } = props
  const initialSection = pageContext?.section > 0 ? pageContext?.section : 1

  const isMobile:boolean = useMedia('mobile')
  const isTablet:boolean = useMedia('tablet')

  const [currentSlide, setCurrentSlide] = useState(initialSection)

  const updateURL = (index:number) => {
    if (typeof window !== 'undefined') {
      window?.history.pushState(null, '', `/${index}`)
    }
  }

  const handleSlideChange = (index:number) => {
    setCurrentSlide(index)
    updateURL(index)
  }

  const scrollListener:any = debounce((event:MouseWheelEvent) => {
    const direction = (event?.deltaY < 0) ? 'up' : 'down'
    const minSlideIndex = 1
    const maxSlideIndex = 4
    let newSlideIndex = currentSlide ?? 1

    if ((direction === 'down') && (currentSlide < maxSlideIndex)) {
      newSlideIndex = currentSlide + 1
    }

    if ((direction === 'up') && (currentSlide > minSlideIndex)) {
      newSlideIndex = currentSlide - 1
    }

    setCurrentSlide(newSlideIndex)
    updateURL(newSlideIndex)
  }, 200)

  React.useEffect (() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('wheel', scrollListener)
      return () => {
        window.removeEventListener('wheel', scrollListener)
      }
    }
  })

  if (isMobile || isTablet) {
    return (
      <Layout>
        <SEO url={props.location.href}/>
        <Header />
        <main>
          <HeroSection goNextSectionClick={() => {}} />
          <DiscoverSection />
          <LearnHistory />
          <AwardsSection />
        </main>
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO url={props.location.href}/>
      <Header />
      <main>
        <Navigation currentItem={currentSlide} onSelect={handleSlideChange}/>
        <PageScroll currentSlide={currentSlide - 1}>
          <HeroSection goNextSectionClick={() => handleSlideChange(2)} />
          <DiscoverSection />
          <LearnHistory />
          <AwardsSection />
        </PageScroll>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default IndexPage
