import React from 'react'
import StyledSpan from './style'

const Subtitle:React.FC = props => {
  return (
    <StyledSpan>
      {props.children}
    </StyledSpan>
  )
}

export default Subtitle
