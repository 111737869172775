import React from 'react'
import { Link } from 'gatsby'
import SectionTitle from 'src/components/Typography/SectionTitle'
import Count from 'src/components/Typography/Count'
import Subtitle from 'src/components/Typography/Subtitle'
import Wrapper from './style'

const LearnSection:React.FC = () => {
  return (
    <Wrapper id="learn" textSide="left" >
      <div className="parallax-group">
        <div id="item-1" className="floating-item" data-deep="1000"/>
        <div id="item-2" className="floating-item" data-deep="700"/>
        <div id="item-3" className="floating-item" data-disable="y" data-deep="500"/>
        <div id="item-4" className="floating-item" data-disable="y" data-deep="300"/>
      </div>
      <div className="text-group">
        <SectionTitle>Узнавайте историю</SectionTitle>
        <p>
          Слушайте аудио-экскурсии или читайте истории к&nbsp;каждому объекту,
           чтобы узнать город лучше и&nbsp;заработать
           <Link to="/rules/#rating"> очки&nbsp;рейтинга</Link>.
        </p>
        <div className="row">
          <div className="column">
            <div className="icons">
              <div className="item-1" />
              <div className="item-2" />
            </div>
            <Count>30+</Count>
            <Subtitle>Историй, озвученных<br/> профессионалами</Subtitle>
          </div>
          <div className="column">
            <div className="icons">
              <div className="item-3" />
            </div>
            <Count>10</Count>
            <Subtitle>Игровых рангов, которые<br/> можно&nbsp;получить изучая город</Subtitle>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default LearnSection
