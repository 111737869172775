import React from 'react'
import StyledH3 from './style'

interface Props {
  float?: string
  className?: string
  children: React.ReactNode
}

const H3 = (props: Props) => {
  return (
    <StyledH3
      float={props.float}
      className={props.className}
    >
      {props.children}
    </StyledH3>
  )
}

export default H3
