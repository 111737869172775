import React from 'react'
import styled from 'styled-components'

export interface IPageScroll extends React.HTMLProps<HTMLDivElement> {
  currentSlide: number
}

export const Wrapper = styled.div<IPageScroll>`
  display: flex;
  /* height: calc(var(--vh) * 100); */
  height: 100%;
  width: 100vw;
  overflow: hidden;

  & > div {
    --currentSlide: ${props => props.currentSlide ? props.currentSlide : 0 };
    --viewport: calc(var(--vh) * 100);
    --offsetHeight: max(var(--viewport), 66rem);

    display: flex;
    flex-flow: column;
    /* height: calc(var(--vh) * 100); */
    height: 100%;
    width: 100%;
    transition: transform 1000ms ease-in-out 0s;
    outline: none;
    transform: translate3d(0, calc(-100% * var(--currentSlide)), 0);

    & > section {
      height: calc(var(--vh) * 100);
      width: 100%;
      max-height: calc(var(--vh) * 100);
    }
  }

`
