import styled from 'styled-components'
import { up } from 'src/utils/media'

interface Props {
  id?: string
  textSide: string
}

const Wrapper = styled.section<Props>`
  display: flex;
  width: ${props => props.theme.containerWidth.sm};
  margin: 0 auto;
  height: 100%;
  padding: 4rem 0;
  position: relative;
  flex: 0 0 auto;

  ${up('mobile')} {
    width: ${props => props.theme.containerWidth.md};
  }

  ${up('tablet')} {
    flex: 1 0 100%;
    padding: 0;
    height: calc(100 * var(--vh));
    min-height: 50rem;
    flex-flow: row nowrap !important;
    align-items: center;
    width: ${props => props.theme.containerWidth.lg};
  }

  ${up('desktop')} {
    /* padding: 15% 0 10% 0; */
    min-height: 80rem;
    width: ${props => props.theme.containerWidth.lg};
  }

  ${up('largeDesktop')} { 
    width: ${props => props.theme.containerWidth.xl};
  }

  & > div {
    margin: 0 auto;
  }

  & > .parallax-group {
    width: 100%;
    order: 0;
    margin-bottom: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;

    ${up('tablet')} {
      /* padding-top: 5%; */
      padding-top: 0;
      width: 50%;
      margin-bottom: 0;
      order: ${props => (props.textSide === 'left') ? 1 : 0};
      ${props => (props.textSide === 'left') ? `
        padding-left: 2rem;
      ` : `
        padding-right: 2rem;
      `}
    }

    ${up('desktop')} {
      padding-top: 0;
    }

    & > .parallax-item, & > .floating-item {
      position: absolute;
      width: 23rem;
      height: 30rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      /* transform: translate3d(0,0,0); */
      transform: translateY(0);
      /* perspective: none;
      transform-style: preserve-3d; */
      /* will-change: transform; */

      ${up('tablet')} {
        width: 21vw;
        height: 27vw;
      }
    }
  }

  & > .text-group {
    display: flex;
    flex-flow: column;
    width: 100%;
    order: 1;

    ${up('tablet')} {
      /* padding-top: 5%; */
      padding-top: 0;
      order: ${props => (props.textSide === 'left') ? 0 : 1};
      width: 50%;
      height: auto;
      flex: 1 0 auto;
      ${props => (props.textSide === 'left') ? `
        padding-right: 2rem;
        padding-left: 10%;
      ` : `
        padding-left: 2rem;
        padding-right: 10%;
      `}
    }

    ${up('desktop')} {
      padding-top: 0;
      ${props => (props.textSide === 'left') ? `
        padding-left: 15%;
      ` : `
        padding-right: 15%;
      `}
    }

    ${up('largeDesktop')} {
      ${props => (props.textSide === 'left') ? `
        padding-left: 15%;
      ` : `
        padding-left: 5%;
      `}
    }

    div.icons {
      display: flex;
      margin-bottom: 2.5rem;
      justify-content: center;

      ${up('tablet')} {
        justify-content: flex-start;
      }

      ${up('largeDesktop')} {
        margin-bottom: 7rem;
      }

      & > div {
        flex-shrink: 0;
        width: 5.4rem;
        height: 6rem;
        margin-right: 3rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left center;

        &:nth-last-child(1) {
          margin-right: 0!important;
        }

        ${up('tablet')} {
          width: 5.4rem;
          height: 6rem;
          margin-right: 3rem;
        }

        ${up('largeDesktop')} {
          width: 10.8rem;
          height: 12rem;
          margin-right: 6rem;
        }
      }
    }

    & p {
      margin-bottom: 6rem;

      ${up('tablet')} {
        max-width: 34rem;
        margin-bottom: 6rem;
      }

      ${up('desktop')} {
        margin-bottom: 8rem;
      } 

      ${up('largeDesktop')} {
        max-width: 68rem;
        margin-bottom: 15rem;
      }   

      & a {
        color: ${props => props.theme.colors.primary};
        transition: color .7s ease-out;

        &:hover, &:active {
          color: ${props => props.theme.colors.primaryLight};
        }
      }   
    }

    span {
      text-align: center;

      ${up('tablet')} {
        text-align: left;
      }
    }
  }

  .row {
    display: flex;
    flex-flow: column;
    
    ${up('tablet')} {
      flex-flow: row;
    }
  }

  .column {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-bottom: 4rem;

    ${up('tablet')} {
      margin-bottom: 0;
      max-width: 50%;
      width: auto;
      padding-right: 6rem;
    }

    ${up('desktop')} {
      padding-right: 8rem;
    }

    ${up('largeDesktop')} {
      padding-right: 15rem;
    }
  }
`

export default Wrapper
