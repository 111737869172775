import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.button`
  display: flex;
  flex-shrink: 0;
  outline: none;
  width: auto;
  margin: 0 auto;
  cursor: pointer;
  padding: .85em 4em;
  box-shadow: .5rem .7rem 4rem ${props => props.theme.colors.shadow};
  border-width: 0;
  border-radius: 3rem;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  font-family: ${props => props.theme.fontFamily};
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: .015em;
  text-align: center;
  justify-content: center;
  transform: scale(1) translateZ(0) perspective(1px);
  backface-visibility: hidden;
  transition: all .7s ease-out;
  -webkit-font-smoothing: subpixel-antialiased;

  &:hover, &:active {
    background: ${props => props.theme.colors.primaryLight};
    transform-style: preserve-3d;
    transform: scale(1.06) translateZ(0) perspective(1px);
  }

  ${up('largeDesktop')} {
    border-radius: 6rem;
    font-size: 3.2rem;
  }

`

export default Wrapper
