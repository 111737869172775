import React from 'react'
import { Link } from 'gatsby'
import SectionTitle from 'src/components/Typography/SectionTitle'
import Count from 'src/components/Typography/Count'
import Subtitle from 'src/components/Typography/Subtitle'
import Footer from 'src/layout/Footer'
import Wrapper from './style'

const AwardsSection:React.FC = () => {
  return (
    <Wrapper id="awards" textSide="right" >
      <div className="parallax-group">
        <div id="item-1" className="floating-item" data-deep="1000"/>
        <div id="item-2" className="floating-item" data-deep="700"/>
        <div id="item-3" className="floating-item" data-disable="y" data-deep="500"/>
        <div id="item-4" className="floating-item" data-disable="y" data-deep="300"/>
        <div id="item-5" className="floating-item" data-deep="100"/>
      </div>
      <div className="text-group">
        <SectionTitle>Получайте призы</SectionTitle>
        <p>
          Выполняйте <Link to="/rules/#levels">задания</Link> и&nbsp;соревнуйтесь
          с&nbsp;другими игроками! Все победители квеста получат классные призы и&nbsp;подарки.
          Не&nbsp;упустите свой шанс стать Почетным&nbsp;Горожанином!
        </p>
        <div className="row">
          <div className="column">
            <div className="icons">
              <div className="item-1" />
            </div>
            <Count>30</Count>
            <Subtitle>Внутриигровых<br/>достижений</Subtitle>
          </div>
          <div className="column">
            <div className="icons">
              <div className="item-2" />
            </div>
            <Count>3</Count>
            <Subtitle>Реальных приза<br/>может выиграть каждый</Subtitle>
          </div>
        </div>
      </div>
      <Footer />
    </Wrapper>
  )
}

export default AwardsSection
