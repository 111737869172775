import styled from 'styled-components'
import { up } from 'src/utils/media'

interface Props {
  className: string
}

const Wrapper = styled.div<Props>`
  display: flex;
  position: fixed;
  top: 50%;
  right: 5rem;
  transform: translateY(-50%);
  z-index: 9900;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-out;

  ${up('tablet')} {
    display: flex;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    transition: all .3s ease-out .6s;
  }

  & > ul {
    list-style: none;
    float: right;
    text-align: right;
    padding: 0;

    & > li {
      padding: 0 0 3.4rem 0;
      cursor: pointer;
      transition: all .7s ease-out;
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 2.5rem;
        right: 3px;
        width: 1px;
        height: calc(100% - 1.8rem - .5em);
        background-color: ${props => props.theme.colors.secondary[500]};
        transition: all .7s ease-out;
      }

      & > span {
        font-size: 1.3rem;
        font-family: ${props => props.theme.fontFamily};
        letter-spacing: .01em;
        line-height: 1;
        color: ${props => props.theme.colors.secondary[500]};
        transition: all .7s ease-out;
      }

      & > span.bullet {
        position: relative;
        display: inline-block;
        width: .7rem;
        height: .7rem;
        border-radius: 50%;
        margin-left: 2rem;
        background-color: none;
        border: 1px solid ${props => props.theme.colors.secondary[500]};
        transition: all .7s ease-out;
      }

      &:hover, &.active {
        & > span {
          color: ${props => props.theme.colors.bodyText};
        }

        & > span.bullet {
          background-color: ${props => props.theme.colors.primary};
          border: 1px solid ${props => props.theme.colors.primary};
        }
      }

      &.active {
        padding-bottom: 10rem;

        &::after {
          background-color: ${props => props.theme.colors.primary};
        }
      }

      &:nth-last-child(1) {
        padding-bottom: 0!important;

        &::after {
          display: none!important;
        }
      }
    }
  }

`

export default Wrapper
