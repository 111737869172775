import styled from 'styled-components'
import Section from 'src/sections/MainSections'
import { up } from 'src/utils/media'
import { floating } from 'src/utils/animations/floating'

const Icon1 = require('src/public/icons/1628.svg')
const Icon2 = require('src/public/icons/1658.svg')

const Layer1 = require('src/public/images/3-layer0.svg')
const Layer2 = require('src/public/images/3-layer1.svg')
const Layer3 = require('src/public/images/3-layer2.svg')
const Layer4 = require('src/public/images/3-layer3.svg')
const Layer5 = require('src/public/images/3-layer4.svg')

const Steps4 = require('src/public/images/steps-path-4.jpg')

const Wrapper = styled(Section)`
  padding-bottom: 8rem;
  position: relative;

  ${up('tablet')} {
    background-image: url(${Steps4});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  div.parallax-group {
    
    #item-1 {
      background-image: url(${Layer1});
    }
    #item-2 {
      background-image: url(${Layer2});
      ${floating('floatingLarge', 3, 0.5)}
    }
    #item-3 {
      background-image: url(${Layer3});
      ${floating('floatingSmall', 3, 0.3)}
    }
    #item-4 {
      background-image: url(${Layer4});
      ${floating('floatingSmall', 3, 0)}
    }
    #item-5 {
      background-image: url(${Layer5});
      ${floating('floatingLarge', 3, 0.3)}
    }
  }

  .icons > div {

    &.item-1 {
      background-image: url(${Icon1});
    }
    
    &.item-2 {
      background-image: url(${Icon2});
    }    
  }

  .row {
    flex-flow: row nowrap;
  }

  .column {
    width: 50%;

    ${up('tablet')} {
      width: auto;
    }
  }

`

export default Wrapper
