import React from 'react'
import { Link } from 'gatsby'
import SectionTitle from 'src/components/Typography/SectionTitle'
import Count from 'src/components/Typography/Count'
import Subtitle from 'src/components/Typography/Subtitle'
import Wrapper from './style'

const DiscoverSection:React.FC = () => {
  return (
    <Wrapper id="discover" textSide="right" >
      <div className="parallax-group">
        <div id="item-1" className="floating-item" data-deep="1000"/>
        <div id="item-2" className="floating-item" data-deep="700"/>
        <div id="item-3" className="floating-item" data-disable="y" data-deep="500"/>
        <div id="item-4" className="floating-item" data-disable="y" data-deep="300"/>
      </div>
      <div className="text-group">
        <SectionTitle>Открывайте места</SectionTitle>
        <p>Находите исторические и&nbsp;памятные места города.
          Самые активные и&nbsp;быстрые участники получат
          <Link to="/rules/#general"> классные призы </Link>
           в&nbsp;финале!
        </p>
        <div className="icons">
          <div className="item-1" />
          <div className="item-2" />
          <div className="item-3" />
        </div>
        <Count>100+</Count>
        <Subtitle>значимых мест <br/>и&nbsp;достопримечательностей</Subtitle>
      </div>
    </Wrapper>
  )
}

export default DiscoverSection
