import React from 'react'
import {
  IPageScroll,
  Wrapper
} from './style'

const PageScroll:React.FC<IPageScroll> = props => {
  return (
    <Wrapper currentSlide={props.currentSlide}>
      <div>
        {props.children}
      </div>
    </Wrapper>
  )
}

export default PageScroll
