import React, { ReactNode } from 'react'
import Wrapper from './style'

interface Props {
  children?: ReactNode
  onClick(event:any): void
}

const PrimaryButton = (props:Props) => {
  return (
    <Wrapper onClick={props.onClick}>
      {props.children}
    </Wrapper>
  )
}

export default PrimaryButton
