import styled from 'styled-components'
import Section from 'src/sections/MainSections'
import { up } from 'src/utils/media'
import { floating } from 'src/utils/animations/floating'

const Icon1 = require('src/public/icons/1558.svg')
const Icon2 = require('src/public/icons/1562.svg')
const Icon3 = require('src/public/icons/1569.svg')

const Layer1 = require('src/public/images/1-layer0.svg')
const Layer2 = require('src/public/images/1-layer1.svg')
const Layer3 = require('src/public/images/1-layer2.svg')
const Layer4 = require('src/public/images/1-layer3.svg')

const Steps2 = require('src/public/images/steps-path-2.jpg')

const Wrapper = styled(Section)`

  ${up('tablet')} {    
    background-image: url(${Steps2});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  ${up('desktop')} {      
    min-height: 100rem;
  }

  div.parallax-group {
  
    #item-1 {
      background-image: url(${Layer1});
    }
    #item-2 {
      background-image: url(${Layer2});
      ${floating('floatingLarge', 3, 0.5)}
    }
    #item-3 {
      background-image: url(${Layer3});
      ${floating('floatingSmall', 3, 0.3)}
    }
    #item-4 {
      background-image: url(${Layer4});
      ${floating('floatingSmall', 3, 0)}
    }
  }

  div.icons > div {

    &.item-1 {
      background-image: url(${Icon1});
    }
    
    &.item-2 {
      background-image: url(${Icon2});
    }
    
    &.item-3 {
      background-image: url(${Icon3});
    }
  }

`

export default Wrapper
