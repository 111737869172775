import React from 'react'
import StyledCount from './style'

const Count:React.FC = props => {
  return (
    <StyledCount>
      {props.children}
    </StyledCount>
  )
}

export default Count
