import styled from 'styled-components'
import { up } from 'src/utils/media'

const StyledSpan = styled.span`
  display: block;
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.colors.secondary[500]};
  font-size: 1.3rem;
  letter-spacing: .015em;
  line-height: 1.5;
  font-weight: 500;

  ${up('mobile')} {
    font-size: 1.3rem;
  }

  ${up('desktop')} {
    font-size: 1.4rem;
  }

  ${up('largeDesktop')} {
    font-size: 2.8rem;
  }
`

export default StyledSpan
