import React from 'react'
import H1 from 'src/components/Typography/H1'
import H3 from 'src/components/Typography/H3'
import PrimaryButton from 'src/components/Buttons/Primary'
import Logo from 'src/components/Logo'
//@ts-ignore
import AppStore from 'src/public/icons/app-store-black.inline.svg'
//@ts-ignore
import PlayMarket from 'src/public/icons/play-market.inline.svg'
import Wrapper from './style'

interface Props {
  goNextSectionClick(): void
}

const HeroSection:React.FC<Props> = props => {
  return (
    <Wrapper>
      <div className="parallax-group">
        <div id="item-1" className="parallax-item" data-deep="30"/>
        <div id="item-2" className="parallax-item" data-deep="40"/>
        <div id="item-3" className="parallax-item" data-deep="50"/>
        <div id="item-4" className="parallax-item" data-deep="70"/>
        <div id="item-5" className="parallax-item" data-deep="150"/>
        <div id="item-6" className="parallax-item" data-deep="30"/>
        <div id="item-7" className="parallax-item" data-deep="20"/>
        <div id="item-8" className="parallax-item" data-deep="70"/>
        <div id="item-9" className="parallax-item" data-deep="90"/>
        <div id="item-10" className="parallax-item" data-deep="30"/>
        <div id="item-11" className="parallax-item" data-deep="90"/>
        <div id="item-12" className="parallax-item" data-deep="90"/>
        <div id="item-13" className="parallax-item" data-deep="50"/>
      </div>
      <div className="main-group">
        <Logo />
        <H3 float="center">Получите звание</H3>
        <H1 float="center">Почетного Горожанина</H1>
        <p>
          Посещайте значимые места&nbsp;Бийска,
          соревнуйтесь с&nbsp;другими участниками и&nbsp;получайте
          реальные&nbsp;награды!
        </p>
      </div>
      <div className="market mobile">
        <a className="get_ios" href="/"><AppStore /></a>
        <a className="get_android" href="/"><PlayMarket /></a>
      </div>
      <div className="desktop">
        <PrimaryButton onClick={props.goNextSectionClick}>Начать!</PrimaryButton>
      </div>

    </Wrapper>
  )
}

export default HeroSection
