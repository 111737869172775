import styled from 'styled-components'
import { up } from 'src/utils/media'
// import { fadeIn } from 'src/utils/animations/fadeIn'

const Icon1 = require('src/public/icons/1550.svg')
const Icon2 = require('src/public/icons/1600.svg')
const Icon3 = require('src/public/icons/1569.svg')
const Icon4 = require('src/public/icons/1577.svg')
const Icon5 = require('src/public/icons/1721.svg')
const Icon6 = require('src/public/icons/1558.svg')
const Icon7 = require('src/public/icons/1552.svg')
const Icon8 = require('src/public/icons/1573.svg')
const Icon9 = require('src/public/icons/1649.svg')
const Icon10 = require('src/public/icons/1605.svg')
const Icon11 = require('src/public/icons/1680.svg')
const Icon12 = require('src/public/icons/1669.svg')
const Icon13 = require('src/public/icons/1562.svg')

const Steps1 = require('src/public/images/steps-path-1.jpg')

const Wrapper = styled.section`
  height: 100%;
  min-height: calc(100 * var(--vh));
  padding: 10rem 0 4rem;
  justify-content: center;
  text-align: center;
  position: relative;
  flex: 0 0 auto;

  ${up('tablet')} {
    padding: 12% 0 5% 0;
    height: calc(100 * var(--vh));
    min-height: 50rem;
    background-image: url(${Steps1});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  ${up('desktop')} {      
    padding: 15% 0 10% 0;
    min-height: 80rem;
  }

  & > div {
    margin: 0 auto;
  }

  & > div.main-group {
    width: ${props => props.theme.containerWidth.sm};
    margin-bottom: 6rem;
    
    @media screen and (max-height: 600px) {
      margin-bottom: 4rem;        
    }

    ${up('mobile')} {
      margin-bottom: 6rem;
    }
    
    ${up('tablet')} {
      width: ${props => props.theme.containerWidth.lg};
      margin-bottom: 8rem;
    }

    ${up('largeDesktop')} {      
      margin-bottom: 15rem;
    }

    & > img {
      margin-bottom: 8rem;

      @media screen and (max-height: 600px) {
        margin-bottom: 6rem;        
      }

      ${up('tablet')} {
        margin-bottom: 4rem;
      }

      ${up('desktop')} {      
        margin-bottom: 5rem;
      }
      ${up('largeDesktop')} {
        margin-bottom: 15rem;
      }
    }

    & > h3 {
      text-transform: uppercase;
      margin-bottom: 1em;

      ${up('desktop')} {      
        margin-bottom: 1.25em;
      }
    }

    & > h1 {
      margin-bottom: 1em;

      ${up('desktop')} {      
        margin-bottom: 1.6em;
      }
    }

    & > p {
      max-width: 36rem;
      margin: 0 auto;

      ${up('largeDesktop')} {
        max-width: 70rem;
      }
    }
  }

  & > .parallax-group {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    
    @media screen and (max-height: 600px) {
      top: 1rem;
      height: calc(100% - 1rem);
    }


    ${up('largeDesktop')} {
      height: 90%;
    }

    & > .parallax-item {
      position: absolute;
      width: 4rem;
      height: 4.4rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transform: translate(0,0);
      transform-style: preserve-3d;

      ${up('desktop')} {        
        width: 5.2rem;
        height: 5.7rem;
      }
      ${up('largeDesktop')} {
        width: 10.4rem;
        height: 11.4rem;
      }
    }

    #item-1 {
      top: 25%;
      left: 0.8rem;
      background-image: url(${Icon1});
      
      ${up('mobile')} {
        top: 25%;
        left: 4%;
      }

      ${up('tablet')} {
        top: 40%;
        left: 4%;
      }

      ${up('desktop')} {
        left: 7%;
      }
    }

    #item-2 {
      top: 20%;
      left: 7.5rem;
      background-image: url(${Icon2});

      @media screen and (max-height: 600px) {
        top: 15%;
        left: 5.5rem;
      }

      ${up('mobile')} {
        top: 25%;
        left: 17%;
      }

      ${up('tablet')} {
        top: 33%;
        left: 17%;
      }

      ${up('desktop')} {
        left: 20%;
      }
    }

    #item-3 {
      top: 32%;
      left: 6.5rem;
      background-image: url(${Icon3});

      ${up('mobile')} {
        top: 35%;
        left: 11%;
      }

      ${up('tablet')} {
        top: 55%;
        left: 14%;
      }

      ${up('desktop')} {
        left: 17%;
      }
    }

    #item-4 {
      display: none;
      background-image: url(${Icon4});

      ${up('mobile')} {
        display: block;
        top: 45%;
        left: 5%;
      }

      ${up('tablet')} {
        top: 72%;
        left: 6%;
      }

      ${up('desktop')} {
        left: 9%;
      }
    }

    #item-5 {
      display: none;
      background-image: url(${Icon5});

      ${up('mobile')} {
        display: block;
        top: 32%;
        left: 28%;
      }

      ${up('tablet')} {
        top: 65%;
        left: 26%;
      }

      ${up('desktop')} {
        left: 29%;
      }
    }

    #item-6 {
      top: 40%;
      left: 1rem;
      background-image: url(${Icon6});

      @media screen and (max-height: 600px) {
        display: none;
      }

      ${up('mobile')} {
        top: 18%;
        left: 35%;
      }

      ${up('tablet')} {
        top: 80%;
        left: 17%;
      }

      ${up('desktop')} {
        left: 20%;
      }
    }

    #item-7 {
      top: 20%;
      right: 7.5rem;
      background-image: url(${Icon7});

      @media screen and (max-height: 600px) {
        top: 15%;
        right: 5.5rem;
      }
      
      ${up('mobile')} {
        top: 20%;
        right: 30%;
      }

      ${up('tablet')} {
        top: 30%;
        right: 8%;
      }

      ${up('desktop')} {
        right: 11%;
      }
    }
    
    #item-8 {
      top: 25%;
      right: .8rem;
      background-image: url(${Icon8});

      ${up('mobile')} {
        top: 25%;
        right: 4%;
      }

      ${up('tablet')} {
        top: 40%;
        right: 19%;
      }

      ${up('desktop')} {
        right: 22%;
      }
    }

    #item-9 {
      display: none;
      background-image: url(${Icon9});

      ${up('mobile')} {
        display: block;
        top: 30%;
        right: 25%;
      }

      ${up('tablet')} {
        top: 48%;
        right: 4%;
      }

      ${up('desktop')} {
        right: 7%;
      }
    }
    
    #item-10 {
      top: 32%;
      right: 6.5rem;
      background-image: url(${Icon10});

      ${up('mobile')} {
        top: 36%;
        right: 13%;
      }

      ${up('tablet')} {
        top: 60%;
        right: 14%;
      }

      ${up('desktop')} {
        right: 17%;
      }
    }

    #item-11 {
      display: none;
      bottom: 25%;
      right: 2.5rem;
      background-image: url(${Icon11});

      @media screen and (max-height: 600px) {
        display: none;
      }

      ${up('mobile')} {
        display: block;
        bottom: unset;
        top: 50%;
        right: 7%;
      }

      ${up('tablet')} {
        top: 75%;
        right: 22%;
      }

      ${up('desktop')} {
        right: 25%;
      }
    }

    #item-12 {
      top: 40%;
      right: 1rem;
      background-image: url(${Icon12});

      @media screen and (max-height: 600px) {
        display: none;
      }

      ${up('tablet')} {
        top: 72%;
        right: 4%;
      }

      ${up('desktop')} {
        right: 7%;
      }
    }
    
    #item-13 {      
      display: none;
      background-image: url(${Icon13});

      ${up('mobile')} {
        display: block;
        top: 14%;
        right: 16%;
      }

      ${up('tablet')} {
        top: 82%;
        right: 10%;
      }

      ${up('desktop')} {
        right: 13%;
      }
    }

  }

  .market {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    margin: 0;

    ${up('mobile')} {
      width: 50%;
      margin: 0 auto;
    }

    a {
      display: flex;
      height: 4rem;
      width: auto;
      margin: 0 auto;
    }
  }

  /* .desktop {
    position: realtive;

    &::after {
      --path-height: 5vw;
      
      content: '';
      display: block;
      position: absolute;
      width: calc(var(--path-height) * 8.75 / 118);
      height: var(--path-height);
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      background-image: url(${Steps1});
      background-size: contain;
      background-position: bottom center;
      background-repeat: no-repeat;
    }
  } */

`

export default Wrapper
