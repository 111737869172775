import React from 'react'
import Wrapper from './style'

interface Props {
  onSelect(index:number): void
  currentItem: number
}

const items = [
  'В начало',
  'Открывайте',
  'Изучайте',
  'Выигрывайте'
]

const Navigation:React.FC<Props> = props => {
  const { onSelect, currentItem } = props

  return (
    <Wrapper className={currentItem >= 2 ? 'visible' : ''}>
      <ul>
        {items.map((item, index) => (
          <li
            key={index}
            className={index === currentItem - 1 ? 'active' : ''}>
            <span onClick={() => onSelect(index + 1)}>{item}</span>
            <span className="bullet"></span>
          </li>
        ))}
      </ul>
    </Wrapper>
  )
}

export default Navigation
