import styled from 'styled-components'
import { up } from 'src/utils/media'

const StyledSpan = styled.span`
  display: block;
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.colors.bodyText};
  font-size: 2rem;
  letter-spacing: .015em;
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: .75em;

  ${up('mobile')} {
    font-size: 2.8rem;
  }

  ${up('desktop')} {
    font-size: 3rem;
  }

  ${up('largeDesktop')} {
    font-size: 6rem;
  }
`

export default StyledSpan
