import styled from 'styled-components'
import Section from 'src/sections/MainSections'
import { floating } from 'src/utils/animations/floating'
import { up, down } from 'src/utils/media'

const Icon1 = require('src/public/icons/1777.svg')
const Icon2 = require('src/public/icons/1778.svg')
const Icon3 = require('src/public/icons/1672.svg')

const Layer1 = require('src/public/images/2-layer0.svg')
const Layer2 = require('src/public/images/2-layer1.svg')
const Layer3 = require('src/public/images/2-layer2.svg')
const Layer4 = require('src/public/images/2-layer3.svg')

const Steps3 = require('src/public/images/steps-path-3.jpg')

const Wrapper = styled(Section)`

  ${up('tablet')} {    
    background-image: url(${Steps3});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  ${down('desktop')} {
    .column:nth-child(2) {
      margin-bottom: 0;
    }
  }

  div.parallax-group {
    
    #item-1 {
      background-image: url(${Layer1});
    }
    #item-2 {
      background-image: url(${Layer2});
      ${floating('floatingLarge', 3, 0.5)}
    }
    #item-3 {
      background-image: url(${Layer3});
      ${floating('floatingSmall', 3, 0.3)}
    }
    #item-4 {
      background-image: url(${Layer4});
      ${floating('floatingSmall', 3, 0)}
    }
  }

  .icons > div {

    &.item-1 {
      background-image: url(${Icon1});
    }
    
    &.item-2 {
      background-image: url(${Icon2});
    }
    
    &.item-3 {
      background-image: url(${Icon3});
    }
  }

`

export default Wrapper
